<template>
  <div class="modal-instruction" @click.self="$emit('close-modal', $event)">
    <div class="content-modal">
      <!-- CLOSE -->
      <figure @click="$emit('close-modal', $event)" class="icon-close">
        <img
          src="../../assets/images/instruction/md_close.svg"
          alt="icon close"
        />
      </figure>

      <div slot="body" class="responsive-height">
        <div class="md_head">
          <div class="logo">
            <img
              src="../../assets/images/instruction/md_logo.svg"
              alt="ChibiJob"
            />
          </div>
          <h2 class="">
            キャンペーンに<br
              class="br-sp"
            />応募しながら<br />ポイントが貯まる！
          </h2>
          <div class="ill">
            <img
              src="../../assets/images/instruction/ms_kv_ill.png"
              alt="キャンペーンに応募しながらポイントが貯まる ！"
            />
          </div>
        </div>
        <div class="md_mttl">
          <span>落選しても</span>
          <h4>必ずポイントがもらえる！</h4>
        </div>
        <div class="md_img">
          <picture>
            <source
              media="(min-width:781px)"
              srcset="../../assets/images/instruction/md_main_img.png"
            />
            <source
              media="(max-width:780px)"
              srcset="../../assets/images/instruction/md_main_img-s.png"
            />
            <img
              src="../../assets/images/instruction/md_main_img.png"
              alt="落選しても必ずポイントがもらえる！"
              class="switching"
            />
          </picture>
        </div>
        <p>
          初心者でも安心して、毎日お得に楽しめる懸賞サイトです。<br />
          気になるあの商品もポイントGETで“実質無料”で買えるかも！？<br />
          貯まったポイントは1P=1円として、様々なサービスと交換できます。
        </p>
        <div class="btn md_gr">
          <router-link to="/registration">
            <p>無料会員登録</p>
          </router-link>
        </div>
        <h3>コンテンツの詳細はこちら！</h3>
        <div class="c_ttl01">
          <h6>キャンペーン応募で貯まる</h6>
        </div>
        <div class="c_flow01">
          <picture>
            <source
              media="(min-width:781px)"
              srcset="../../assets/images/instruction/md_flow01.png"
            />
            <source
              media="(max-width:780px)"
              srcset="../../assets/images/instruction/md_flow01-s.png"
            />
            <img
              src="../../assets/images/instruction/md_flow01.png"
              alt="キャンペーン応募で貯まる"
              class="switching"
            />
          </picture>
        </div>
        <div class="c_ttl02">
          <h6>キャンペーンサイトを見て貯まる</h6>
        </div>
        <div class="c_flow02">
          <picture>
            <source
              media="(min-width:781px)"
              srcset="../../assets/images/instruction/md_flow02.png"
            />
            <source
              media="(max-width:780px)"
              srcset="../../assets/images/instruction/md_flow02-s.png"
            />
            <img
              src="../../assets/images/instruction/md_flow02.png"
              alt="キャンペーンサイトを見て貯まる"
              class="switching"
            />
          </picture>
        </div>
        <div class="c_ttl03">
          <h6>毎日広告を見て貯まる</h6>
        </div>
        <div class="c_flow03">
          <picture>
            <source
              media="(min-width:781px)"
              srcset="../../assets/images/instruction/md_flow03.png"
            />
            <source
              media="(max-width:780px)"
              srcset="../../assets/images/instruction/md_flow03-s.png"
            />
            <img
              src="../../assets/images/instruction/md_flow03.png"
              alt="毎日広告を見て貯まる"
              class="switching"
            />
          </picture>
        </div>
        <p>
          その他キャンペーン以外にも、オリジナルコンテンツが盛りだくさん！<br
            class="br-pc"
          />毎日ログインして、簡単に楽しく、もっとお得に暮らそう！
        </p>
        <div class="pointuse">
          <picture>
            <source
              media="(min-width:781px)"
              srcset="../../assets/images/instruction/pointuse.png"
            />
            <source
              media="(max-width:780px)"
              srcset="../../assets/images/instruction/pointuse-s.png"
            />
            <img
              src="../../assets/images/instruction/pointuse.png"
              alt="毎日広告を見て貯まる"
              class="switching"
            />
          </picture>
        </div>
        <h3>ポイント交換先</h3>
        <div class="point_wrap">
          <div class="point_img">
            <picture>
              <source
                media="(min-width:781px)"
                srcset="../../assets/images/instruction/md_point.png"
              />
              <source
                media="(max-width:780px)"
                srcset="../../assets/images/instruction/md_point-s.png"
              />
              <img
                src="../../assets/images/instruction/md_point.png"
                alt="ポイント交換先"
                class="switching"
              />
            </picture>
          </div>
        </div>
        <div class="btn md_gr">
          <router-link to="/registration">
            <p>無料会員登録</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalInstruction'
}
</script>

<style lang="scss" scoped>
$sp: 780px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 780;
@function pv($num, $width: $viewport) {
  @return calc(1vw * $num * 100 / $width);
}

.modal-instruction {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  padding: 80px 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-instruction .content-modal {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 60px;
  transform: translate3d(0, 0, 0);
  position: relative;
  background: #fff;
  max-width: 800px;
  border-radius: 10px;
  text-align: center;
  margin: auto;

  .icon-close {
    position: absolute;
    z-index: 40;
    width: 50px;
    height: 50px;
    right: -25px;
    top: -25px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
  }

  .md_head {
    background-image: url(../../assets/images/instruction/md_kv_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 800px;
    height: 213px;
    position: relative;

    .logo {
      position: absolute;
      top: 32px;
      left: 35px;

      img {
        max-width: 168px;
      }
    }

    .ill {
      position: absolute;
      top: 73px;
      right: 106px;

      img {
        max-width: 158px;
      }
    }

    h2 {
      font-family: A1 Gothic B, Noto Sans JP, sans-serif;
      font-size: 34px;
      line-height: 1.5;
      color: #c6aa44;
      position: absolute;
      top: 97px;
      left: 80px;
      text-align: left;
    }
  }

  .md_mttl {
    background-image: url(../../assets/images/instruction/md_mttl_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 800px;
    height: 92px;
    position: relative;

    span {
      font-family: A1 Gothic M, Noto Sans JP, sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 1;
      color: #fff;
      position: absolute;
      top: 44px;
      left: 150px;
    }

    h4 {
      font-family: A1 Gothic B, Noto Sans JP, sans-serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.5;
      color: #fff;
      position: absolute;
      top: 34px;
      left: 313px;
      text-align: left;
    }
  }

  .md_img {
    margin: 0 auto 20px;
    text-align: center;

    img {
      max-width: 456px;
    }
  }

  p {
    text-align: center;
    font-family: A1 Gothic R, Noto Sans JP, sans-serif;
    font-size: 16px;
    line-height: 2.2;
    color: #282828;
  }

  .md_gr {
    // background-color: #7c934c;
    // width: 352px;
    // height: 70px;
    // border-radius: 150px;
    margin: 20px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      width: 352px;
      height: 70px;
      border-radius: 150px;
      background-color: #7c934c;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      text-align: center;
      font-family: A1 Gothic M, Noto Sans JP, sans-serif;
      font-size: 18px;
      line-height: 1;
      color: #fff;
      position: relative;
      display: block;
    }

    p:after {
      content: "";
      background-image: url(../../assets/images/instruction/md_arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 23px;
      height: 22px;
      position: absolute;
      top: calc(50% - 11px);
      right: -100px;
    }
  }

  h3 {
    background-color: rgba(198, 170, 68, 0.1);
    text-align: center;
    text-align: center;
    font-family: A1 Gothic M, Noto Sans JP, sans-serif;
    font-size: 21px;
    line-height: 1;
    color: #c6aa44;
    padding: 60px 0;
    margin: 70px auto 0;
  }

  .c_ttl01 {
    border-radius: 4px;
    background-color: #c6aa44;
    padding: 25px 0;
    text-align: center;
    width: 672px;
    margin: -40px auto 0;

    h6 {
      font-family: A1 Gothic M, Noto Sans JP, sans-serif;
      font-size: 19px;
      line-height: 1;
      color: #fff;
      position: relative;
    }

    h6:before {
      content: "";
      background-image: url(../../assets/images/instruction/md_ico01.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 22px;
      height: 22px;
      position: absolute;
      top: calc(50% - 11px);
      left: 20px;
    }
  }

  .c_flow01 {
    text-align: center;
    margin: 15px auto 35px;
    position: relative;

    img {
      max-width: 472px;
    }
  }

  .c_flow01:after {
    content: "";
    background-image: url(../../assets/images/instruction/md_flow01_after.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 119px;
    height: 64px;
    position: absolute;
    top: -36px;
    right: 88px;
  }

  .c_ttl02 {
    border-radius: 4px;
    background-color: #c6aa44;
    padding: 25px 0;
    text-align: center;
    width: 672px;
    margin: 0 auto 0;

    h6 {
      font-family: A1 Gothic M, Noto Sans JP, sans-serif;
      font-size: 19px;
      line-height: 1;
      color: #fff;
      position: relative;
    }

    h6:before {
      content: "";
      background-image: url(../../assets/images/instruction/md_ico02.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 22px;
      height: 22px;
      position: absolute;
      top: calc(50% - 11px);
      left: 20px;
    }
  }

  .c_flow02 {
    text-align: center;
    margin: 15px auto 35px;
    position: relative;

    img {
      max-width: 472px;
    }
  }

  .c_flow02:after {
    content: "";
    background-image: url(../../assets/images/instruction/md_flow02_after.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 119px;
    height: 64px;
    position: absolute;
    top: -36px;
    right: 88px;
  }

  .c_ttl03 {
    border-radius: 4px;
    background-color: #c6aa44;
    padding: 25px 0;
    text-align: center;
    width: 672px;
    margin: 0 auto 0;

    h6 {
      font-family: A1 Gothic M, Noto Sans JP, sans-serif;
      font-size: 19px;
      line-height: 1;
      color: #fff;
      position: relative;
    }

    h6:before {
      content: "";
      background-image: url(../../assets/images/instruction/md_ico03.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 25px;
      height: 24px;
      position: absolute;
      top: calc(50% - 12px);
      left: 20px;
    }
  }

  .c_flow03 {
    text-align: center;
    margin: 15px auto 35px;

    img {
      max-width: 346px;
    }
  }

  .pointuse {
    max-width: 800px;
    margin: 65px auto 60px;

    img {
      max-width: 100%;
    }
  }

  .point_wrap {
    border-radius: 4px;
    border: 3px solid #e4d9ab;
    background-color: #fff;
    padding: 40px 0;
    text-align: center;
    width: 672px;
    margin: -40px auto 40px;

    .point_img {
      width: 538px;
      margin: 0 auto;

      img {
        max-width: 100%;
      }
    }
  }
}

.br-sp {
  display: none;
}

@media #{$info-phone} {
  .modal-instruction {
    padding: 10vw 0;

    .content-modal .icon-close {
      // background: transparent;
      background: #ffffff;
      border: 0;
      position: absolute;
      top: 3vw;
      right: 3vw;
      // background-image: url(../img/md_close.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 8vw;
      height: 8vw;
      z-index: 9999;
      cursor: pointer;
    }

    .content-modal {
      // position: relative;
      padding: 0 0 12vw;
      background: #fff;
      max-width: 90vw;
      border-radius: pv(10);
      text-align: center;
      margin: auto;
      // z-index: 1000;

      .md_head {
        background-image: url(../../assets/images/instruction/md_kv_bg-s.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 90vw;
        height: 32.3846vw;
        position: relative;

        .logo {
          position: absolute;
          top: 8vw;
          left: 3vw;

          img {
            max-width: 26.9vw;
          }
        }

        .ill {
          position: absolute;
          top: 15vw;
          right: 8vw;

          img {
            max-width: 26.025vw;
          }
        }

        h2 {
          font-family: A1 Gothic B, Noto Sans JP, sans-serif;
          font-size: pv(42);
          line-height: 1.5;
          color: #c6aa44;
          position: absolute;
          top: 15vw;
          left: 10vw;
          text-align: left;
        }
      }

      .md_mttl {
        background-image: url(../../assets/images/instruction/md_mttl_bg-s.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 92.3076vw;
        height: 14.846vw;
        margin: 7vw 0 0 -2.3076vw;
        position: relative;

        span {
          font-family: A1 Gothic M, Noto Sans JP, sans-serif;
          font-size: pv(28);
          font-weight: 500;
          line-height: 1;
          color: #fff;
          position: absolute;
          top: 6.6vw;
          left: 14vw;
        }

        h4 {
          font-family: A1 Gothic B, Noto Sans JP, sans-serif;
          font-size: pv(34);
          font-weight: 700;
          line-height: 1.5;
          color: #fff;
          position: absolute;
          top: 4.8vw;
          left: 37vw;
          text-align: left;
        }
      }

      .md_img {
        margin: 0 5vw 3vw 10vw;
        text-align: center;

        img {
          max-width: 75.2vw;
        }
      }

      p {
        text-align: center;
        font-family: A1 Gothic R, Noto Sans JP, sans-serif;
        font-size: pv(26);
        line-height: 2;
        color: #282828;
        text-align: left;
        margin: 0 5vw;
      }

      .md_gr {
        // background-color: #7c934c;
        // width: 70vw;
        // height: 14.2vw;
        // border-radius: pv(150);
        margin: 3vw auto 0;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          width: 70vw;
          height: 14.2vw;
          border-radius: pv(150);
          background-color: #7c934c;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          text-align: center;
          font-family: A1 Gothic M, Noto Sans JP, sans-serif;
          font-size: pv(32);
          line-height: 1;
          color: #fff;
          position: relative;
          display: block;
        }

        p:after {
          content: "";
          background-image: url(../../assets/images/instruction/md_arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 4.6vw;
          height: 4.6vw;
          position: absolute;
          top: calc(50% - 2.3vw);
          right: -20vw;
        }
      }

      h3 {
        background-color: rgba(198, 170, 68, 0.1);
        text-align: center;
        text-align: center;
        font-family: A1 Gothic M, Noto Sans JP, sans-serif;
        font-size: pv(30);
        line-height: 1;
        color: #c6aa44;
        padding: 10vw 0;
        margin: 12vw auto 0;
      }

      .c_ttl01 {
        border-radius: pv(4);
        background-color: #c6aa44;
        padding: 5vw 0;
        text-align: center;
        width: 80vw;
        margin: -5vw auto 0;

        h6 {
          font-family: A1 Gothic M, Noto Sans JP, sans-serif;
          font-size: pv(30);
          line-height: 1;
          color: #fff;
          position: relative;
        }

        h6:before {
          content: "";
          background-image: url(../../assets/images/instruction/md_ico01.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 4.1vw;
          height: 4.1vw;
          position: absolute;
          top: calc(50% - 2.05vw);
          left: 4vw;
        }
      }

      .c_flow01 {
        text-align: center;
        margin: 1.5vw auto 5vw;
        position: relative;

        img {
          max-width: 80vw;
        }
      }

      .c_flow01:after {
        content: "";
        background-image: url(../../assets/images/instruction/md_flow01_after-s.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 18vw;
        height: 10vw;
        position: absolute;
        top: -6vw;
        right: -5vw;
      }

      .c_ttl02 {
        border-radius: 4px;
        background-color: #c6aa44;
        padding: 5vw 0;
        text-align: center;
        width: 80vw;
        margin: 0 auto 0;

        h6 {
          font-family: A1 Gothic M, Noto Sans JP, sans-serif;
          font-size: pv(30);
          line-height: 1;
          color: #fff;
          position: relative;
        }

        h6:before {
          content: "";
          background-image: url(../../assets/images/instruction/md_ico02.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 4.1vw;
          height: 4.1vw;
          position: absolute;
          top: calc(50% - 2.05vw);
          left: 4vw;
        }
      }

      .c_flow02 {
        text-align: center;
        margin: 1.5vw auto 5vw;
        position: relative;

        img {
          max-width: 80vw;
        }
      }

      .c_flow02:after {
        content: "";
        background-image: url(../../assets/images/instruction/md_flow02_after-s.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 18vw;
        height: 10vw;
        position: absolute;
        top: -6vw;
        right: -5vw;
      }

      .c_ttl03 {
        border-radius: 4px;
        background-color: #c6aa44;
        padding: 5vw 0;
        text-align: center;
        width: 80vw;
        margin: 0 auto 0;

        h6 {
          font-family: A1 Gothic M, Noto Sans JP, sans-serif;
          font-size: pv(30);
          line-height: 1;
          color: #fff;
          position: relative;
        }

        h6:before {
          content: "";
          background-image: url(../../assets/images/instruction/md_ico03.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 4.8vw;
          height: 4.8vw;
          position: absolute;
          top: calc(50% - 2.4vw);
          left: 4vw;
        }
      }

      .c_flow03 {
        text-align: center;
        margin: 1.5vw auto 5vw;

        img {
          max-width: 57vw;
        }
      }

      .pointuse {
        max-width: 90vw;
        margin: 10vw auto;

        img {
          max-width: 100%;
        }
      }

      .point_wrap {
        border-radius: pv(6);
        border: pv(3) solid #e4d9ab;
        background-color: #fff;
        padding: 7vw 0;
        text-align: center;
        width: 80vw;
        margin: -5vw auto 10vw;

        .point_img {
          width: 72.4vw;
          margin: 0 auto;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .br-sp {
    display: block;
  }
}
</style>
