<template>
  <div id="calloutElm">
    <span id="closeButton" class="closebtn" @click="$emit('close-modal', $event)"></span>
    <div class="callout-container" @click="$emit('open-modal', $event)">
      <div class="md_btn btn"><a href="#modal01"></a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalHowToUse'
}
</script>

<style lang="scss" scoped>
#calloutElm {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 174px;
  z-index: 30;

  .callout-container {
    width: 100%;
    cursor: pointer;

    .md_btn {
      background-image: url(../../assets/images/instruction/md_btn.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 174px;
      height: 174px;
    }
  }
  .closebtn {
    position: absolute;
    top: 0;
    right: 11px;
    cursor: pointer;
    z-index: 120;
    width: 28px;
    height: 28px;
    background-image: url(../../assets/images/instruction/md_btn_close.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

@media #{$info-phone} {
  #calloutElm {
    position: fixed;
    bottom: 15vw;
    right: 0;
    left: 70vw;
    max-width: 30vw;

    .callout-container {
      width: 100%;

      .md_btn {
        background-image: url(../../assets/images/instruction/md_btn.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 30vw;
        height: 30vw;
      }
    }
    .closebtn {
      position: absolute;
      top: 0;
      right: 1.5vw;
      cursor: pointer;
      z-index: 120;
      max-width: 6.5vw;
      background-image: url(../../assets/images/instruction/md_btn_close.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 6.5vw;
      height: 6.5vw;
    }
  }
}
</style>
