<template>
  <div>
    <modal-instruction
      v-if="isShowModalIntruction"
      @close-modal="closeModalIntruction"
    />
    <modal-how-to-use
      v-if="isShowModalHowToUse"
      @open-modal="openModalIntruction"
      @close-modal="closeModalHowToUse"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ModalInstruction from '@/components/home/ModalInstruction.vue'
import ModalHowToUse from '@/components/home/ModalHowToUse.vue'

const INTRUCTION_DATE = 'cbj_intruction_date'
const HOW_TO_USE_DATE = 'cbj_how_to_use_date'
const INTRUCTION_DATE_EXPIRED = 30 // day
const HOW_TO_USE_DATE_EXPIRED = 7 // day

export default {
  name: 'HowToUse',

  components: {
    ModalInstruction,
    ModalHowToUse
  },

  data () {
    return {
      isShowModalIntruction: false,
      isShowModalHowToUse: false
    }
  },

  computed: {
    ...mapState('auth', ['token', 'profile'])
  },

  methods: {
    openModalIntruction () {
      this.isShowModalIntruction = true
      document.body.style.overflow = 'hidden'
    },
    closeModalIntruction () {
      this.isShowModalIntruction = false
      document.body.style.overflow = ''

      const instructionExpired = new Date()
      instructionExpired.setDate(instructionExpired.getDate() + INTRUCTION_DATE_EXPIRED)
      localStorage.setItem(INTRUCTION_DATE, instructionExpired)
    },
    openModalHowToUse () {
      this.isShowModalHowToUse = true
    },
    closeModalHowToUse () {
      this.isShowModalHowToUse = false

      const howToUseExpired = new Date()
      howToUseExpired.setDate(howToUseExpired.getDate() + HOW_TO_USE_DATE_EXPIRED)
      localStorage.setItem(HOW_TO_USE_DATE, howToUseExpired)
    }
  },

  mounted () {
    // check condition open modal
    if (this.token && this.profile) return

    const intructionDate = localStorage.getItem(INTRUCTION_DATE)
    const howToUseDate = localStorage.getItem(HOW_TO_USE_DATE)
    const now = new Date()

    if (!intructionDate || now >= new Date(intructionDate)) {
      this.openModalIntruction()
      this.openModalHowToUse()

      // clear expired date
      localStorage.setItem(HOW_TO_USE_DATE, '')
    }

    if (!howToUseDate || now >= new Date(howToUseDate)) {
      this.openModalHowToUse()
    }
  }
}
</script>
