import { render, staticRenderFns } from "./ModalHowToUse.vue?vue&type=template&id=6e2fda52&scoped=true&"
import script from "./ModalHowToUse.vue?vue&type=script&lang=js&"
export * from "./ModalHowToUse.vue?vue&type=script&lang=js&"
import style0 from "./ModalHowToUse.vue?vue&type=style&index=0&id=6e2fda52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e2fda52",
  null
  
)

export default component.exports